import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "1064646b-8368-4e54-aa63-6990243a3928"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader1064646b83684e54Aa636990243a3928(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader1064646b83684e54Aa636990243a3928 site-header`}>
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd parentTag="HeaderTop6e971ef4731d465fA56c933e1e7490db" {...props} />}
      </div>
      <div className="relative hero-wrapper">
        <div className="background-image" />
        {props["hero"] || <Hero parentTag={buildClassName("HeroAd0fc7c9Af5a4bd8A570C12a9685cd9e", parentTag)} postSlug="home-hero" {...props} />}
      </div>
    </div>
  );
}

// id: "557b84b8-a43b-49c2-b8d4-85665761af2d"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer557b84b8A43b49c2B8d485665761af2d(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="Footer557b84b8A43b49c2B8d485665761af2d" {...props} />
  );
}

// id: "d6ac9f4f-79a6-40d8-a0b4-0e368f3d70ad"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "4c0ec4d3-c2ef-4b58-b361-ea2e6b31d949"
export function FooterD6ac9f4f79a640d8A0b40e368f3d70ad(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterD6ac9f4f79a640d8A0b40e368f3d70ad" {...props} />
  );
}

// id: "f1ecc397-6e08-451c-a18a-75182f29dfdf"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "0b7fa8f0-a91e-4162-8430-a8c3f65cfad6"
export function HeaderTopF1ecc3976e08451cA18a75182f29dfdf(props: any) {
  return (
    <DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd parentTag="HeaderTopF1ecc3976e08451cA18a75182f29dfdf" {...props} />
  );
}

// id: "9f55605b-f021-4a37-8c4c-66e3aa74a5a9"
// title: ""
// type: :text
// key: "title"
// parent_id: "1cc2fbe6-87dd-4443-8be3-4b51f8416503"
export const Title9f55605bF0214a378c4c66e3aa74a5a9 = "Join Us";

// id: "f55a1e6a-1d5e-40a2-b4d0-493e62ff01cb"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb page-body`}>
      {props["marketingCards"] || <MarketingCards4bfed234D551400e86bbE22efc0cd4e8 parentTag="MarketingCardsB19d670c610340b4B6da8956769973ad" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "9ca0ec3e-b8a8-4869-a86a-50b76675060c"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage9ca0ec3eB8a84869A86a50b76675060c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage9ca0ec3eB8a84869A86a50b76675060c`}>
      {props["header"] || <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header91295fb426174969973843bb49aa96f6" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterF7d68e3fF8484189827944021593c805" {...props} />}
    </div>
  );
}

// id: "579fd886-42e2-45b8-bed7-e2b9df28d8ca"
// title: ""
// type: :reference
// key: "header"
// parent_id: "bbc514a6-f5e0-4878-97f6-ae00b27e0983"
export function Header579fd88642e245b8Bed7E2b9df28d8ca(props: any) {
  return (
    <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="Header579fd88642e245b8Bed7E2b9df28d8ca" {...props} />
  );
}

// id: "f86d582a-22d1-45f0-8f45-f52921284e11"
// title: ""
// type: :text
// key: "alt"
// parent_id: "cb468982-6efb-42f4-ae63-38aec5efe513"
export const AltF86d582a22d145f08f45F52921284e11 = "logo";

// id: "4dc821ca-4e2d-4b56-81b0-61ab86ded5e1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "31b14535-85cb-412e-a100-18d0c90dfc01"
export function Footer4dc821ca4e2d4b5681b061ab86ded5e1(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="Footer4dc821ca4e2d4b5681b061ab86ded5e1" {...props} />
  );
}

// id: "8a312578-8dec-46fc-bdc4-75fe75a03485"
// title: ""
// type: :text
// key: "class"
// parent_id: "0fc89265-ac48-4d41-a472-2db5e50e956c"
export const Class8a3125788dec46fcBdc475fe75a03485 = "navigation";

// id: "bbc514a6-f5e0-4878-97f6-ae00b27e0983"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePagesBbc514a6F5e0487897f6Ae00b27e0983(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePagesBbc514a6F5e0487897f6Ae00b27e0983`}>
      {props["header"] || <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="Header579fd88642e245b8Bed7E2b9df28d8ca" {...props} />}
      {props.children}
    </div>
  );
}

// id: "a91e5a5c-efc1-4198-8891-a26885fe31e5"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageA91e5a5cEfc141988891A26885fe31e5(props: any) {
  return (
    <SecondaryPageB90a7b863a3b4ee0B0df45090e04e561 parentTag="JoinUsPageA91e5a5cEfc141988891A26885fe31e5" header={<SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header4352ea0b34a9435891499cd55411cd1d" title="Join Us" />} {...props} />
  );
}

// id: "e689eef5-3e58-44f7-96c7-0eaf2d60b5ea"
// title: ""
// type: :reference
// key: "header"
// parent_id: "8272e800-f2a6-4481-bdf8-aff6220b4314"
export function HeaderE689eef53e5844f796c70eaf2d60b5ea(props: any) {
  return (
    <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="HeaderE689eef53e5844f796c70eaf2d60b5ea" title="About Susan" {...props} />
  );
}

// id: "d12debd1-1252-4eaa-b522-f508a5adda88"
// title: ""
// type: :reference
// key: "about-susan-header"
// parent_id: "dbd505f4-ee9b-4e5a-a9f8-4f7a8bdd27b2"
export function AboutSusanHeaderD12debd112524eaaB522F508a5adda88(props: any) {
  return (
    <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="AboutSusanHeaderD12debd112524eaaB522F508a5adda88" {...props} />
  );
}

// id: "9db6daab-980b-4b02-8f49-6fbb3e786147"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "4bfed234-d551-400e-86bb-e22efc0cd4e8"
export const PostSlug9db6daab980b4b028f496fbb3e786147 = "home-page-posts2";

// id: "2399e69e-39f7-41fe-909d-a41eed3cb361"
// title: ""
// type: :text
// key: "class"
// parent_id: "da69d913-61ec-451c-a764-fd5bd42b7d9f"
export const Class2399e69e39f741fe909dA41eed3cb361 = "linked-logo";

// id: "e30fa844-d553-472d-8786-6f91bedb4589"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderE30fa844D553472d87866f91bedb4589(props: any) {
  return (
    <DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd parentTag="MembersAreaHeaderE30fa844D553472d87866f91bedb4589" navMenuSlug="members-primary-nav" header-top={<DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd parentTag="HeaderTop712c5c4eEb2841fa87119e3d2b6118e3" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "27fe5b42-1caa-4c24-9ce8-1324c327e09c"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title27fe5b421caa4c249ce81324c327e09c = "Leveling";

// id: "de55b8cb-1f81-4ce0-b33a-b4eaaab11a36"
// title: "Header Top"
// type: :reference
// key: "header-top"
// parent_id: "00fefa8d-4510-4a77-a9b7-c00887756745"
export function HeaderTopDe55b8cb1f814ce0B33aB4eaaab11a36(props: any) {
  return (
    <DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd parentTag="HeaderTopDe55b8cb1f814ce0B33aB4eaaab11a36" {...props} />
  );
}

// id: "e9fd6ce8-1c88-47d3-8855-7ec7df838a0e"
// title: ""
// type: :text
// key: "title"
// parent_id: "3d113915-a893-4369-ac69-4ff505c6ace8"
export const TitleE9fd6ce81c8847d388557ec7df838a0e = null;

// id: "2cc24c8e-9d43-4e86-91a7-88cf947fd7af"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "3f8d7c49-612a-4abc-8b89-ae7b96a4f101"
export const NavMenuSlug2cc24c8e9d434e8691a788cf947fd7af = "footer-nav";

// id: "cddd988b-76cf-4f88-a2ad-d5d4bbd6abc1"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function LabelCddd988b76cf4f88A2adD5d4bbd6abc1(props: any) {
  return (
    <DefaultLogoCb4689826efb42f4Ae6338aec5efe513 parentTag="LabelCddd988b76cf4f88A2adD5d4bbd6abc1" {...props} />
  );
}

// id: "4c0ec4d3-c2ef-4b58-b361-ea2e6b31d949"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault4c0ec4d3C2ef4b58B361Ea2e6b31d949(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault4c0ec4d3C2ef4b58B361Ea2e6b31d949 page`}>
      {props["header"] || <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="HeaderF2bb9e0e231b4a19897c587ab1668edf" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterD6ac9f4f79a640d8A0b40e368f3d70ad" {...props} />}
    </div>
  );
}

// id: "8705d23f-949c-4ece-a4d0-1d4cec5c9d8e"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "00fefa8d-4510-4a77-a9b7-c00887756745"
export const NavMenuSlug8705d23f949c4eceA4d01d4cec5c9d8e = "members-primary-nav";

// id: "9823bd96-4a7f-4b22-9a30-de0ff5d0aac3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a5d18669-cb75-4de8-94b5-99a91c2295cb"
export function Header9823bd964a7f4b229a30De0ff5d0aac3(props: any) {
  return (
    <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header9823bd964a7f4b229a30De0ff5d0aac3" title="Resources" {...props} />
  );
}

// id: "460d00c0-ab36-439f-8fe0-9c583c08381a"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header460d00c0Ab36439f8fe09c583c08381a(props: any) {
  return (
    <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="Header460d00c0Ab36439f8fe09c583c08381a" {...props} />
  );
}

// id: "51f059fb-b430-4cb6-a14b-b58ab90a1914"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages51f059fbB4304cb6A14bB58ab90a1914(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages51f059fbB4304cb6A14bB58ab90a1914`}>
      {props["header"] || <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="HeaderF8e603aa57d74615A05611acd6bcf52b" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav575d8e4fD8bc4766A7ac9496eb9a6476", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="Footer912c1c2cBb9246a7953a0fa38fef414a" {...props} />}
    </div>
  );
}

// id: "0137f77b-c2ef-4adb-be52-b902318f3117"
// title: ""
// type: :html
// key: "logo"
// parent_id: "abebe797-0198-40cf-8bb8-73e98cab7620"
export function Logo0137f77bC2ef4adbBe52B902318f3117(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/logo-wide-white.svg" alt="Demo 1 logo" className={`${parentTag || ""} Logo0137f77bC2ef4adbBe52B902318f3117 logo`} />
  );
}

// id: "76e235bc-54c0-4c83-9d68-1c197cb08415"
// title: ""
// type: :reference
// key: "header"
// parent_id: "40cad30d-d7f9-420d-ab0b-10fff5ac9ade"
export function Header76e235bc54c04c839d681c197cb08415(props: any) {
  return (
    <HomeHeader1064646b83684e54Aa636990243a3928 parentTag="Header76e235bc54c04c839d681c197cb08415" {...props} />
  );
}

// id: "575d8e4f-d8bc-4766-a7ac-9496eb9a6476"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "51f059fb-b430-4cb6-a14b-b58ab90a1914"
export function SecondaryNav575d8e4fD8bc4766A7ac9496eb9a6476(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav575d8e4fD8bc4766A7ac9496eb9a6476", parentTag)} {...props} />
  );
}

// id: "d3082bf6-f3d4-4a7d-a6a7-1e9c465bd41c"
// title: ""
// type: :text
// key: "url"
// parent_id: "da69d913-61ec-451c-a764-fd5bd42b7d9f"
export const UrlD3082bf6F3d44a7dA6a71e9c465bd41c = "/";

// id: "31b14535-85cb-412e-a100-18d0c90dfc01"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage31b1453585cb412eA10018d0c90dfc01(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage31b1453585cb412eA10018d0c90dfc01`}>
      {props["header"] || <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header5ce8a07aA34a4213A664F14089bd1f7b" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="Footer4dc821ca4e2d4b5681b061ab86ded5e1" {...props} />}
    </div>
  );
}

// id: "9950f1d7-ee48-4939-92b4-73ae7cc03b0e"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e hero`}>
      <div className="title">
        {props["title"] || Title4f59a804795f4e68867021a6e4787a60}
      </div>
    </div>
  );
}

// id: "912c1c2c-bb92-46a7-953a-0fa38fef414a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "51f059fb-b430-4cb6-a14b-b58ab90a1914"
export function Footer912c1c2cBb9246a7953a0fa38fef414a(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="Footer912c1c2cBb9246a7953a0fa38fef414a" {...props} />
  );
}

// id: "5ce8a07a-a34a-4213-a664-f14089bd1f7b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "31b14535-85cb-412e-a100-18d0c90dfc01"
export function Header5ce8a07aA34a4213A664F14089bd1f7b(props: any) {
  return (
    <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header5ce8a07aA34a4213A664F14089bd1f7b" title="Leveling" {...props} />
  );
}

// id: "1cc2fbe6-87dd-4443-8be3-4b51f8416503"
// title: ""
// type: :reference
// key: "header"
// parent_id: "67149a2b-d0ac-4e32-8d77-e541ef4a785a"
export function Header1cc2fbe687dd44438be34b51f8416503(props: any) {
  return (
    <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header1cc2fbe687dd44438be34b51f8416503" title="Join Us" {...props} />
  );
}

// id: "b84033ba-8633-4a72-a27f-60aea56b74a5"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesB84033ba86334a72A27f60aea56b74a5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesB84033ba86334a72A27f60aea56b74a5`}>
      {props.children}
    </div>
  );
}

// id: "8272e800-f2a6-4481-bdf8-aff6220b4314"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage8272e800F2a64481Bdf8Aff6220b4314(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage8272e800F2a64481Bdf8Aff6220b4314 page`}>
      {props["header"] || <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="HeaderE689eef53e5844f796c70eaf2d60b5ea" title="About Susan" {...props} />}
      <main className="main">
        About Susan - TBD
      </main>
      {props["footer"] || <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterAa230a0922554d3a9392D034e2685347" {...props} />}
    </div>
  );
}

// id: "4bfed234-d551-400e-86bb-e22efc0cd4e8"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards4bfed234D551400e86bbE22efc0cd4e8(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards4bfed234D551400e86bbE22efc0cd4e8", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "40cad30d-d7f9-420d-ab0b-10fff5ac9ade"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage40cad30dD7f9420dAb0b10fff5ac9ade(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage40cad30dD7f9420dAb0b10fff5ac9ade page`}>
      {props["header"] || <HomeHeader1064646b83684e54Aa636990243a3928 parentTag="Header76e235bc54c04c839d681c197cb08415" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackF0edfa85721746e6Baa163aaf5baf944", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb parentTag="Body0053ad2561d7489cB533A335f7383977" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote4a73b73165b241fd9bc33af0c830a3e8", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="Footer0337a2a0C4144cda925aF011c3acbacf" {...props} />}
    </div>
  );
}

// id: "cb468982-6efb-42f4-ae63-38aec5efe513"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoCb4689826efb42f4Ae6338aec5efe513(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/logo-wide-white.svg" className={`${parentTag || ""} DefaultLogoCb4689826efb42f4Ae6338aec5efe513 logo`} alt="logo" />
  );
}

// id: "fe76cbcd-c5cc-41e2-a49d-4e2320e0c5aa"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa hero`}>
      <div className="title">
        Remember you are the Magic
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button463c08a4D76343b7B2366484975f081e", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "4f59a804-795f-4e68-8670-21a6e4787a60"
// title: ""
// type: :text
// key: "title"
// parent_id: "9950f1d7-ee48-4939-92b4-73ae7cc03b0e"
export const Title4f59a804795f4e68867021a6e4787a60 = "Default Title";

// id: "ed0b1c69-248b-4abd-9232-2b4e29c904ff"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "1064646b-8368-4e54-aa63-6990243a3928"
export const BackgroundImageEd0b1c69248b4abd92322b4e29c904ff = "https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/hero4.jpg";

// id: "7c186fc7-c9ef-468f-9d23-a31e62c24c7a"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "ad0fc7c9-af5a-4bd8-a570-c12a9685cd9e"
export const PostSlug7c186fc7C9ef468f9d23A31e62c24c7a = "home-hero";

// id: "d3a31027-4707-44ee-88f4-f2af8b649416"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "b19d670c-6103-40b4-b6da-8956769973ad"
export const PostSlugD3a31027470744ee88f4F2af8b649416 = "home-page-posts2";

// id: "6557217a-1839-482f-9da9-8f22d942f89c"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href6557217a1839482f9da98f22d942f89c = "/";

// id: "abebe797-0198-40cf-8bb8-73e98cab7620"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterAbebe797019840cf8bb873e98cab7620(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterAbebe797019840cf8bb873e98cab7620 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links3f8d7c49612a4abc8b89Ae7b96a4f101", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo0137f77bC2ef4adbBe52B902318f3117 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright14e4f454Cbb34893887f337d05f27bd9", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow427938aa5aa54f0c98dc55f375baa2fa", parentTag)} {...props} />}
    </div>
  );
}

// id: "0337a2a0-c414-4cda-925a-f011c3acbacf"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "40cad30d-d7f9-420d-ab0b-10fff5ac9ade"
export function Footer0337a2a0C4144cda925aF011c3acbacf(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="Footer0337a2a0C4144cda925aF011c3acbacf" {...props} />
  );
}

// id: "3f8d7c49-612a-4abc-8b89-ae7b96a4f101"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "abebe797-0198-40cf-8bb8-73e98cab7620"
export function Links3f8d7c49612a4abc8b89Ae7b96a4f101(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links3f8d7c49612a4abc8b89Ae7b96a4f101", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "5067977a-dba6-4b69-a9f2-f7a5322d9724"
// title: ""
// type: :text
// key: "title"
// parent_id: "e689eef5-3e58-44f7-96c7-0eaf2d60b5ea"
export const Title5067977aDba64b69A9f2F7a5322d9724 = "About Susan";

// id: "7224a96e-52f1-431c-993c-4623189e01e3"
// title: ""
// type: :text
// key: "title"
// parent_id: "e223a33a-1406-4b0a-abd0-9d8fb1e71f8f"
export const Title7224a96e52f1431c993c4623189e01e3 = "Secondary Page";

// id: "b90a7b86-3a3b-4ee0-b0df-45090e04e561"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageB90a7b863a3b4ee0B0df45090e04e561(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageB90a7b863a3b4ee0B0df45090e04e561`}>
      {props["header"] || <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="HeaderE223a33a14064b0aAbd09d8fb1e71f8f" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="Footer01dff936Ab1246639ee2Be72486f2456" {...props} />}
    </div>
  );
}

// id: "0bb1abf7-a6b2-4af4-8820-adb4bbb35174"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "e30fa844-d553-472d-8786-6f91bedb4589"
export const NavMenuSlug0bb1abf7A6b24af48820Adb4bbb35174 = "members-primary-nav";

// id: "4a73b731-65b2-41fd-9bc3-3af0c830a3e8"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "40cad30d-d7f9-420d-ab0b-10fff5ac9ade"
export function Quote4a73b73165b241fd9bc33af0c830a3e8(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote4a73b73165b241fd9bc33af0c830a3e8", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "427938aa-5aa5-4f0c-98dc-55f375baa2fa"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "abebe797-0198-40cf-8bb8-73e98cab7620"
export function Groupflow427938aa5aa54f0c98dc55f375baa2fa(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow427938aa5aa54f0c98dc55f375baa2fa", parentTag)} {...props} />
  );
}

// id: "3d754082-5a26-4cdf-86ef-b5b87102ed6e"
// title: ""
// type: :reference
// key: "label"
// parent_id: "da69d913-61ec-451c-a764-fd5bd42b7d9f"
export function Label3d7540825a264cdf86efB5b87102ed6e(props: any) {
  return (
    <DefaultLogoCb4689826efb42f4Ae6338aec5efe513 parentTag="Label3d7540825a264cdf86efB5b87102ed6e" {...props} />
  );
}

// id: "ca2233b7-e8c7-4f59-864c-29eee8ea55a3"
// title: ""
// type: :text
// key: "title"
// parent_id: "9823bd96-4a7f-4b22-9a30-de0ff5d0aac3"
export const TitleCa2233b7E8c74f59864c29eee8ea55a3 = "Resources";

// id: "67149a2b-d0ac-4e32-8d77-e541ef4a785a"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages67149a2bD0ac4e328d77E541ef4a785a(props: any) {
  return (
    <SecondaryPageB90a7b863a3b4ee0B0df45090e04e561 parentTag="SignupPages67149a2bD0ac4e328d77E541ef4a785a" header={<SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header1cc2fbe687dd44438be34b51f8416503" title="Join Us" />} {...props} />
  );
}

// id: "ad0fc7c9-af5a-4bd8-a570-c12a9685cd9e"
// title: ""
// type: :gf_preset
// key: "hero"
// parent_id: "1064646b-8368-4e54-aa63-6990243a3928"
export function HeroAd0fc7c9Af5a4bd8A570C12a9685cd9e(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Hero parentTag={buildClassName("HeroAd0fc7c9Af5a4bd8A570C12a9685cd9e", parentTag)} postSlug="home-hero" {...props} />
  );
}

// id: "463c08a4-d763-43b7-b236-6484975f081e"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "fe76cbcd-c5cc-41e2-a49d-4e2320e0c5aa"
export function Button463c08a4D76343b7B2366484975f081e(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button463c08a4D76343b7B2366484975f081e", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "1714bc60-2cea-4888-913e-fc205c90d2b8"
// title: ""
// type: :text
// key: "title"
// parent_id: "0b7fa8f0-a91e-4162-8430-a8c3f65cfad6"
export const Title1714bc602cea4888913eFc205c90d2b8 = "Secondary Marketing Title 1";

// id: "8b990c15-9459-40ea-98c5-d663f7d10e4b"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "135f3a91-9b07-47ed-8535-bd8de92cd7ee"
export const PostSlug8b990c15945940ea98c5D663f7d10e4b = "about-us-posts";

// id: "e570ed05-9508-472f-b326-45c0e5d2ab0f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "f2bb9e0e-231b-4a19-897c-587ab1668edf"
export const NavMenuSlugE570ed059508472fB32645c0e5d2ab0f = "members-primary-nav";

// id: "00fefa8d-4510-4a77-a9b7-c00887756745"
// title: "About SuSun"
// type: :html
// key: "about-susan"
// parent_id: nil
export function AboutSusan00fefa8d45104a77A9b7C00887756745(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutSusan00fefa8d45104a77A9b7C00887756745`} navMenuSlug="members-primary-nav">
      {props["about-susan-header"] || <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="AboutSusanHeader18e42361448a4e8c81c165df489bc0d9" {...props} />}
      {props["about-susan-main"] || <AboutSusanMain02331a21860442819c99F7638b869459 />}
      {props["footer"] || <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterFa6b7ad322d64e29Bf393f336f74879a" {...props} />}
    </div>
  );
}

// id: "b19d670c-6103-40b4-b6da-8956769973ad"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "f55a1e6a-1d5e-40a2-b4d0-493e62ff01cb"
export function MarketingCardsB19d670c610340b4B6da8956769973ad(props: any) {
  return (
    <MarketingCards4bfed234D551400e86bbE22efc0cd4e8 parentTag="MarketingCardsB19d670c610340b4B6da8956769973ad" postSlug="home-page-posts2" {...props} />
  );
}

// id: "f2bb9e0e-231b-4a19-897c-587ab1668edf"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4c0ec4d3-c2ef-4b58-b361-ea2e6b31d949"
export function HeaderF2bb9e0e231b4a19897c587ab1668edf(props: any) {
  return (
    <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="HeaderF2bb9e0e231b4a19897c587ab1668edf" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "87d6cc13-57cf-455f-8517-4d4613f83399"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "7bb2acae-2404-4f76-8a5a-57e571b8807b"
export const BackgroundImage87d6cc1357cf455f85174d4613f83399 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "67f13a2a-4a36-4476-aba2-09dadbfc5ce3"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "1664b0d9-2f6d-416d-b543-d1709ae4ad21"
export const NavMenuSlug67f13a2a4a364476Aba209dadbfc5ce3 = "members-primary-nav";

// id: "d74c1b5e-652f-43db-ba51-fdebf4eb889d"
// title: ""
// type: :text
// key: "href"
// parent_id: "463c08a4-d763-43b7-b236-6484975f081e"
export const HrefD74c1b5e652f43dbBa51Fdebf4eb889d = "/join";

// id: "dbd505f4-ee9b-4e5a-a9f8-4f7a8bdd27b2"
// title: "Offerings"
// type: :html
// key: "about-offerings"
// parent_id: nil
export function AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2`}>
      {props["about-susan-header"] || <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="AboutSusanHeaderD12debd112524eaaB522F508a5adda88" {...props} />}
      <main>
        <div>
          <div className="gf-cols-2 content-width">
            <div className="image">
              <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/altar.webp" alt="Altar" className="block-image" />
            </div>
            <div className="text-block-vertical">
              <div className="heading">
                What to expect from a session
              </div>
              <ul>
                <li>
                  Reduced stress and anxiety
                </li>
                <li>
                  Increased energetic awareness
                </li>
                <li>
                  Develop more compassion for yourself and others
                </li>
                <li>
                  More capacity to live the life of your dreams
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hero brave">
          <div className="text-block text-block-vertical content-width">
            <div className="heading">
              You&#39;re so brave for showing up!
            </div>
            <p>
              Humans come to me for a variety of different reasons. Healing is a
              very unique journey for everyone. I want to highlight my different
              offerings, yet create space to co-create something perfect for you.
            </p>
          </div>
        </div>
        <div>
          <div className="grid-blocks content-width">
            <div className="image-text-block">
              <div className="image">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-massage.webp" alt="SuSun massage" />
              </div>
              <div className="text">
                <div className="heading">
                  Tantric Awakening
                </div>
                <p>
                  Feel more in your body.
                </p>
                <p>
                  More pleasure. More space. More freedom.
                </p>
                <p>
                  Allow yourself to relax, receive, and enjoy.
                </p>
              </div>
            </div>
            <div className="image-text-block">
              <div className="image">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/pray-art.webp" alt="Chakra" />
              </div>
              <div className="text">
                <div className="heading">
                  Chakra Alignment
                </div>
                <p>
                  Clear out stagnant energy, quiet the noise.
                </p>
                <p>
                  Through Reiki we can prepare your energetic body for a vibrational
                  shift.
                </p>
                <p>
                  Increased intuitive awareness and psychic sensitivity.
                </p>
              </div>
            </div>
            <div className="image-text-block">
              <div className="image">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/sacred-foot.webp" alt="Kinky Tasting" />
              </div>
              <div className="text">
                <div className="heading">
                  Kinky Tasting
                </div>
                <p>
                  Do you have an itch to explore?
                </p>
                <p>
                  Something you have always wanted to try?
                </p>
                <p>
                  Let&#39;s get a taste of those passions you may be a bit shy to talk
                  about.
                </p>
                <p>
                  Through consent, negotiation and boundaries we can explore in new
                  ways.
                </p>
              </div>
            </div>
            <div className="image-text-block">
              <div className="image">
                <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-guiding.webp" alt="SuSun guiding a student" />
              </div>
              <div className="text">
                <div className="heading">
                  Becoming Sex-Positive
                </div>
                <p>
                  Build your communication skills, knowledge and understanding.
                </p>
                <p>
                  Learn your somatic YES, body awareness and the confidence to bring
                  all of yourself into community.
                </p>
                <p>
                  Take a road trip into new and exciting ways of being in
                  relationship.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a href="/members/messages/x1RwUD7TogtZzODN" className="Button">
            Walking with me
          </a>
        </div>
      </main>
      {props["footer"] || <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterA743e2660f1246ccA86bCcf760970e1d" {...props} />}
    </div>
  );
}

// id: "1664b0d9-2f6d-416d-b543-d1709ae4ad21"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "7bb2acae-2404-4f76-8a5a-57e571b8807b"
export function Navigation1664b0d92f6d416dB543D1709ae4ad21(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation1664b0d92f6d416dB543D1709ae4ad21", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "fa6b7ad3-22d6-4e29-bf39-3f336f74879a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "00fefa8d-4510-4a77-a9b7-c00887756745"
export function FooterFa6b7ad322d64e29Bf393f336f74879a(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterFa6b7ad322d64e29Bf393f336f74879a" {...props} />
  );
}

// id: "44298cbb-6a90-43c8-9f74-4bb22c869c4d"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "cb468982-6efb-42f4-ae63-38aec5efe513"
export const ImageUrl44298cbb6a9043c89f744bb22c869c4d = "https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/logo-wide-white.svg";

// id: "63155f95-1f66-4795-acb9-8c9149697494"
// title: ""
// type: :text
// key: "label"
// parent_id: "463c08a4-d763-43b7-b236-6484975f081e"
export const Label63155f951f664795Acb98c9149697494 = "Join Us";

// id: "ddb8c69c-2fd1-4962-b516-3d0b0997500a"
// title: ""
// type: :text
// key: "title"
// parent_id: "5ce8a07a-a34a-4213-a664-f14089bd1f7b"
export const TitleDdb8c69c2fd14962B5163d0b0997500a = "Leveling";

// id: "0bbc1af4-8c91-494e-ab71-1248b9ad9d8e"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote0bbc1af48c91494eAb711248b9ad9d8e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote0bbc1af48c91494eAb711248b9ad9d8e`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "f0edfa85-7217-46e6-baa1-63aaf5baf944"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "40cad30d-d7f9-420d-ab0b-10fff5ac9ade"
export function WelcomeBackF0edfa85721746e6Baa163aaf5baf944(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackF0edfa85721746e6Baa163aaf5baf944", parentTag)} {...props} />
  );
}

// id: "18e42361-448a-4e8c-81c1-65df489bc0d9"
// title: ""
// type: :reference
// key: "about-susan-header"
// parent_id: "00fefa8d-4510-4a77-a9b7-c00887756745"
export function AboutSusanHeader18e42361448a4e8c81c165df489bc0d9(props: any) {
  return (
    <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="AboutSusanHeader18e42361448a4e8c81c165df489bc0d9" {...props} />
  );
}

// id: "01dff936-ab12-4663-9ee2-be72486f2456"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b90a7b86-3a3b-4ee0-b0df-45090e04e561"
export function Footer01dff936Ab1246639ee2Be72486f2456(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="Footer01dff936Ab1246639ee2Be72486f2456" {...props} />
  );
}

// id: "7ab22ddf-3fe0-4920-bb21-948824440060"
// title: ""
// type: :reference
// key: "label"
// parent_id: "c8365107-cfa1-426a-ab34-d4a818ad3102"
export function Label7ab22ddf3fe04920Bb21948824440060(props: any) {
  return (
    <DefaultLogoCb4689826efb42f4Ae6338aec5efe513 parentTag="Label7ab22ddf3fe04920Bb21948824440060" {...props} />
  );
}

// id: "3d3ddf31-7ce1-47b9-8de3-33dfe5bda8e9"
// title: ""
// type: :text
// key: "class"
// parent_id: "083066da-b7d0-43c9-9106-1508edd4e79a"
export const Class3d3ddf317ce147b98de333dfe5bda8e9 = "navigation";

// id: "a5d18669-cb75-4de8-94b5-99a91c2295cb"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageA5d18669Cb754de894b599a91c2295cb(props: any) {
  return (
    <SecondaryPageB90a7b863a3b4ee0B0df45090e04e561 parentTag="ResourcesPageA5d18669Cb754de894b599a91c2295cb" header={<SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header9823bd964a7f4b229a30De0ff5d0aac3" title="Resources" />} {...props} />
  );
}

// id: "135f3a91-9b07-47ed-8535-bd8de92cd7ee"
// title: ""
// type: :reference
// key: "body"
// parent_id: "8272e800-f2a6-4481-bdf8-aff6220b4314"
export function Body135f3a919b0747ed8535Bd8de92cd7ee(props: any) {
  return (
    <MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb parentTag="Body135f3a919b0747ed8535Bd8de92cd7ee" postSlug="about-us-posts" {...props} />
  );
}

// id: "71312f94-1c3a-4333-b140-855716ad0591"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "0b7fa8f0-a91e-4162-8430-a8c3f65cfad6"
export function Hero71312f941c3a4333B140855716ad0591(props: any) {
  return (
    <SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e parentTag="Hero71312f941c3a4333B140855716ad0591" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "6e971ef4-731d-465f-a56c-933e1e7490db"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "1064646b-8368-4e54-aa63-6990243a3928"
export function HeaderTop6e971ef4731d465fA56c933e1e7490db(props: any) {
  return (
    <DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd parentTag="HeaderTop6e971ef4731d465fA56c933e1e7490db" {...props} />
  );
}

// id: "c65f1094-4c22-47e1-835d-2b572a2b0b6f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0fc89265-ac48-4d41-a472-2db5e50e956c"
export const NavMenuSlugC65f10944c2247e1835d2b572a2b0b6f = "marketing-primary-nav";

// id: "7bb2acae-2404-4f76-8a5a-57e571b8807b"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader7bb2acae24044f768a5a57e571b8807b(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader7bb2acae24044f768a5a57e571b8807b site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoC8365107Cfa1426aAb34D4a818ad3102", parentTag)} label={<DefaultLogoCb4689826efb42f4Ae6338aec5efe513 parentTag="Label7ab22ddf3fe04920Bb21948824440060" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation1664b0d92f6d416dB543D1709ae4ad21", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "c8365107-cfa1-426a-ab34-d4a818ad3102"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "7bb2acae-2404-4f76-8a5a-57e571b8807b"
export function LinkedLogoC8365107Cfa1426aAb34D4a818ad3102(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoC8365107Cfa1426aAb34D4a818ad3102", parentTag)} label={<DefaultLogoCb4689826efb42f4Ae6338aec5efe513 parentTag="Label7ab22ddf3fe04920Bb21948824440060" alt="logo" />} {...props} />
  );
}

// id: "da69d913-61ec-451c-a764-fd5bd42b7d9f"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "691ad00b-75d7-40ca-bca5-10e67bfb3efd"
export function LinkedLogoDa69d91361ec451cA764Fd5bd42b7d9f(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoDa69d91361ec451cA764Fd5bd42b7d9f", parentTag)} className="linked-logo" url="/" label={<DefaultLogoCb4689826efb42f4Ae6338aec5efe513 parentTag="Label3d7540825a264cdf86efB5b87102ed6e" alt="logo" />} {...props} />
  );
}

// id: "0053ad25-61d7-489c-b533-a335f7383977"
// title: ""
// type: :reference
// key: "body"
// parent_id: "40cad30d-d7f9-420d-ab0b-10fff5ac9ade"
export function Body0053ad2561d7489cB533A335f7383977(props: any) {
  return (
    <MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb parentTag="Body0053ad2561d7489cB533A335f7383977" {...props} />
  );
}

// id: "083066da-b7d0-43c9-9106-1508edd4e79a"
// title: "Members Area Navigation"
// type: :gf_preset
// key: "navigation"
// parent_id: "f8e603aa-57d7-4615-a056-11acd6bcf52b"
export function Navigation083066daB7d043c991061508edd4e79a(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation083066daB7d043c991061508edd4e79a", parentTag)} className="navigation" {...props} />
  );
}

// id: "3d113915-a893-4369-ac69-4ff505c6ace8"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader3d113915A8934369Ac694ff505c6ace8(props: any) {
  return (
    <ShortHeader7bb2acae24044f768a5a57e571b8807b parentTag="DefaultHeader3d113915A8934369Ac694ff505c6ace8" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "712c5c4e-eb28-41fa-8711-9e3d2b6118e3"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "e30fa844-d553-472d-8786-6f91bedb4589"
export function HeaderTop712c5c4eEb2841fa87119e3d2b6118e3(props: any) {
  return (
    <DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd parentTag="HeaderTop712c5c4eEb2841fa87119e3d2b6118e3" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "14e4f454-cbb3-4893-887f-337d05f27bd9"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "abebe797-0198-40cf-8bb8-73e98cab7620"
export function Copyright14e4f454Cbb34893887f337d05f27bd9(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright14e4f454Cbb34893887f337d05f27bd9", parentTag)} {...props} />
  );
}

// id: "0fc89265-ac48-4d41-a472-2db5e50e956c"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "691ad00b-75d7-40ca-bca5-10e67bfb3efd"
export function Navigation0fc89265Ac484d41A4722db5e50e956c(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation0fc89265Ac484d41A4722db5e50e956c", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "29238b78-bf61-4910-afff-a5e92b367b35"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "4a73b731-65b2-41fd-9bc3-3af0c830a3e8"
export const ContentSlug29238b78Bf614910AfffA5e92b367b35 = "home-page-quote";

// id: "691ad00b-75d7-40ca-bca5-10e67bfb3efd"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoDa69d91361ec451cA764Fd5bd42b7d9f", parentTag)} className="linked-logo" url="/" label={<DefaultLogoCb4689826efb42f4Ae6338aec5efe513 parentTag="Label3d7540825a264cdf86efB5b87102ed6e" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation0fc89265Ac484d41A4722db5e50e956c", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "aa230a09-2255-4d3a-9392-d034e2685347"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "8272e800-f2a6-4481-bdf8-aff6220b4314"
export function FooterAa230a0922554d3a9392D034e2685347(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterAa230a0922554d3a9392D034e2685347" {...props} />
  );
}

// id: "e223a33a-1406-4b0a-abd0-9d8fb1e71f8f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b90a7b86-3a3b-4ee0-b0df-45090e04e561"
export function HeaderE223a33a14064b0aAbd09d8fb1e71f8f(props: any) {
  return (
    <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="HeaderE223a33a14064b0aAbd09d8fb1e71f8f" title="Secondary Page" {...props} />
  );
}

// id: "f7d68e3f-f848-4189-8279-44021593c805"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "9ca0ec3e-b8a8-4869-a86a-50b76675060c"
export function FooterF7d68e3fF8484189827944021593c805(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterF7d68e3fF8484189827944021593c805" {...props} />
  );
}

// id: "44385f7d-d4a6-469d-8508-00b3bb1f8e2f"
// title: ""
// type: :text
// key: "title"
// parent_id: "91295fb4-2617-4969-9738-43bb49aa96f6"
export const Title44385f7dD4a6469d850800b3bb1f8e2f = "Public Events";

// id: "0b7fa8f0-a91e-4162-8430-a8c3f65cfad6"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd parentTag="HeaderTopF1ecc3976e08451cA18a75182f29dfdf" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e parentTag="Hero71312f941c3a4333B140855716ad0591" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "99ee6e58-12aa-48b9-ae49-95ea2648f0b0"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "3d113915-a893-4369-ac69-4ff505c6ace8"
export const NavMenuSlug99ee6e5812aa48b9Ae4995ea2648f0b0 = "marketing-primary-nav";

// id: "a743e266-0f12-46cc-a86b-ccf760970e1d"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "dbd505f4-ee9b-4e5a-a9f8-4f7a8bdd27b2"
export function FooterA743e2660f1246ccA86bCcf760970e1d(props: any) {
  return (
    <DefaultFooterAbebe797019840cf8bb873e98cab7620 parentTag="FooterA743e2660f1246ccA86bCcf760970e1d" {...props} />
  );
}

// id: "f8e603aa-57d7-4615-a056-11acd6bcf52b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "51f059fb-b430-4cb6-a14b-b58ab90a1914"
export function HeaderF8e603aa57d74615A05611acd6bcf52b(props: any) {
  return (
    <MembersAreaHeaderE30fa844D553472d87866f91bedb4589 parentTag="HeaderF8e603aa57d74615A05611acd6bcf52b" {...props} />
  );
}

// id: "5bb81029-99f5-414e-a333-13e34fd4d09d"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "712c5c4e-eb28-41fa-8711-9e3d2b6118e3"
export const NavMenuSlug5bb8102999f5414eA33313e34fd4d09d = "members-primary-nav";

// id: "91295fb4-2617-4969-9738-43bb49aa96f6"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9ca0ec3e-b8a8-4869-a86a-50b76675060c"
export function Header91295fb426174969973843bb49aa96f6(props: any) {
  return (
    <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header91295fb426174969973843bb49aa96f6" title="Public Events" {...props} />
  );
}

// id: "b6d37220-fbea-411f-a9a1-91c3d4781f76"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "4bfed234-d551-400e-86bb-e22efc0cd4e8"
export const ButtonClassB6d37220Fbea411fA9a191c3d4781f76 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "4352ea0b-34a9-4358-9149-9cd55411cd1d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a91e5a5c-efc1-4198-8891-a26885fe31e5"
export function Header4352ea0b34a9435891499cd55411cd1d(props: any) {
  return (
    <SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 parentTag="Header4352ea0b34a9435891499cd55411cd1d" title="Join Us" {...props} />
  );
}

// id: "8d98d4e1-3d9e-490e-9824-ecadcb020f92"
// title: ""
// type: :text
// key: "title"
// parent_id: "71312f94-1c3a-4333-b140-855716ad0591"
export const Title8d98d4e13d9e490e9824Ecadcb020f92 = "Secondary Marketing Title 2";

// id: "02331a21-8604-4281-9c99-f7638b869459"
// title: ""
// type: :html
// key: "about-susan-main"
// parent_id: "00fefa8d-4510-4a77-a9b7-c00887756745"
export function AboutSusanMain02331a21860442819c99F7638b869459(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} AboutSusanMain02331a21860442819c99F7638b869459`}>
      <div className="background-image">
      </div>
      <div className="text-block first">
        <div className="opacity">
        </div>
        <div className="text">
          <h2 className="heading">
            Welcome to the house of Breath.
          </h2>
          <p>
            Here we can slow down, exhale the old, and breathe in the new.
          </p>
          <p>
            Are you looking for deeper ways to connect with yourself?
          </p>
          <p>
            Do you feel desire to experience MORE?
          </p>
          <p>
            More...
          </p>
          <p>
            pleasure, security, magic, wholeness...
          </p>
          <p>
            MORE TURNED ON WITH YOUR LIFE.
          </p>
          <p>
            Answer the beckoning of the heart,
          </p>
          <p>
            I&#39;m here to support you on this journey.
          </p>
        </div>
      </div>
      <div className="page-row color-scheme-1">
        <div className="text-block centered-page-content">
          <div className="text">
            <p className="heading">
              Discover the magic you behold
            </p>
            <p>
              When we get overwhelmed by life and navigate difficult situations, we
              can forget our own magic. Sometimes we are shut down and disconnected
              from our body, our pleasure, our purpose.
            </p>
            <p>
              It&#39;s okay to ask for help, it&#39;s my honor to help remind you. Let&#39;s
              take time to build trust and learn about what you are missing.
            </p>
            <p>
              Sacred space gives us an opportunity to loosen up tension in the body.
              Clear out old chakra patterns. Remember what it feels like to be your
              magical integrated self.
            </p>
            <p>
              House of Breath is a Temple. Serene, sensual, playful.
            </p>
            <div className="button-container">
              <a href="/about/offerings" className="Button transition">
                Explore my Offerings
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="image-block">
      </div>
      <div className="page-row color-scheme-1">
        <div className="text-block centered-page-content testimonials">
          <div className="heading">
            Testimonials
          </div>
          <div className="testimonial">
            <div className="image-container">
              <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/Shout.jpg" alt="photo of CK" />
            </div>
            <div className="text-container">
              <p>
                I felt a warm, tight feeling in my throat as Susan was working on
                opening my throat chakra. I thought of people who I let take my
                voice away and my inner self said &quot;NO MORE, This is my voice, I will
                speak my truth&quot;
              </p>
              <p>
                — CK
              </p>
            </div>
            <div className="bottom" />
          </div>
          <div className="testimonial">
            <div className="image-container">
              <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/Relaxation.jpeg" alt="photo of BB" />
            </div>
            <div className="text-container">
              <p>
                She has a loving intuition that meets you where you are. She helps
                you relax into healing yourself while allowing the possibilities of
                what your life can be to blossom.
              </p>
              <p>
                — BB
              </p>
            </div>
            <div className="bottom" />
          </div>
          <div className="testimonial">
            <div className="image-container">
              <img src="https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/Touch.jpg" alt="photo of CK" />
            </div>
            <div className="text-container">
              <p>
                Through her touch, Susan makes a visceral connection that reaches my
                core, sending reverberations throughout my body.
              </p>
              <p>
                — R
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-small">
      </div>
      <div className="contact-container">
        <div className="contact-form block color-scheme-2">
          <div className="heading">
            Reach out for a free consultation!
          </div>
          <p>
            When you feel called to any of my services, it begins with a discovery
            call with SuSun. This is to make sure Tastes of Tantra is a good fit for
            yourself and your relationships!
          </p>
          <div className="button-container">
            <a href="/members/messages/x1RwUD7TogtZzODN" className="Button color2 transition">
              Message SuSun
            </a>
          </div>
        </div>
        <div className="image">
        </div>
      </div>
    </main>
  );
}

// id: "f4b47308-5102-47a5-9ee4-6cee6bed721b"
// title: ""
// type: :text
// key: "title"
// parent_id: "4352ea0b-34a9-4358-9149-9cd55411cd1d"
export const TitleF4b47308510247a59ee46cee6bed721b = "Join Us";

const Components = {
  HomeHeader1064646b83684e54Aa636990243a3928,
  Footer557b84b8A43b49c2B8d485665761af2d,
  FooterD6ac9f4f79a640d8A0b40e368f3d70ad,
  HeaderTopF1ecc3976e08451cA18a75182f29dfdf,
  Title9f55605bF0214a378c4c66e3aa74a5a9,
  MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb,
  PublicEventsPage9ca0ec3eB8a84869A86a50b76675060c,
  Header579fd88642e245b8Bed7E2b9df28d8ca,
  AltF86d582a22d145f08f45F52921284e11,
  Footer4dc821ca4e2d4b5681b061ab86ded5e1,
  Class8a3125788dec46fcBdc475fe75a03485,
  MessagePagesBbc514a6F5e0487897f6Ae00b27e0983,
  JoinUsPageA91e5a5cEfc141988891A26885fe31e5,
  HeaderE689eef53e5844f796c70eaf2d60b5ea,
  AboutSusanHeaderD12debd112524eaaB522F508a5adda88,
  PostSlug9db6daab980b4b028f496fbb3e786147,
  Class2399e69e39f741fe909dA41eed3cb361,
  MembersAreaHeaderE30fa844D553472d87866f91bedb4589,
  Title27fe5b421caa4c249ce81324c327e09c,
  HeaderTopDe55b8cb1f814ce0B33aB4eaaab11a36,
  TitleE9fd6ce81c8847d388557ec7df838a0e,
  NavMenuSlug2cc24c8e9d434e8691a788cf947fd7af,
  LabelCddd988b76cf4f88A2adD5d4bbd6abc1,
  MembersAreaDefault4c0ec4d3C2ef4b58B361Ea2e6b31d949,
  NavMenuSlug8705d23f949c4eceA4d01d4cec5c9d8e,
  Header9823bd964a7f4b229a30De0ff5d0aac3,
  Header460d00c0Ab36439f8fe09c583c08381a,
  MemberPages51f059fbB4304cb6A14bB58ab90a1914,
  Logo0137f77bC2ef4adbBe52B902318f3117,
  Header76e235bc54c04c839d681c197cb08415,
  SecondaryNav575d8e4fD8bc4766A7ac9496eb9a6476,
  UrlD3082bf6F3d44a7dA6a71e9c465bd41c,
  AboutLevelingPage31b1453585cb412eA10018d0c90dfc01,
  SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e,
  Footer912c1c2cBb9246a7953a0fa38fef414a,
  Header5ce8a07aA34a4213A664F14089bd1f7b,
  Header1cc2fbe687dd44438be34b51f8416503,
  AdminPagesB84033ba86334a72A27f60aea56b74a5,
  AboutUsPage8272e800F2a64481Bdf8Aff6220b4314,
  MarketingCards4bfed234D551400e86bbE22efc0cd4e8,
  HomePage40cad30dD7f9420dAb0b10fff5ac9ade,
  DefaultLogoCb4689826efb42f4Ae6338aec5efe513,
  HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa,
  Title4f59a804795f4e68867021a6e4787a60,
  BackgroundImageEd0b1c69248b4abd92322b4e29c904ff,
  PostSlug7c186fc7C9ef468f9d23A31e62c24c7a,
  PostSlugD3a31027470744ee88f4F2af8b649416,
  Href6557217a1839482f9da98f22d942f89c,
  DefaultFooterAbebe797019840cf8bb873e98cab7620,
  Footer0337a2a0C4144cda925aF011c3acbacf,
  Links3f8d7c49612a4abc8b89Ae7b96a4f101,
  Title5067977aDba64b69A9f2F7a5322d9724,
  Title7224a96e52f1431c993c4623189e01e3,
  SecondaryPageB90a7b863a3b4ee0B0df45090e04e561,
  NavMenuSlug0bb1abf7A6b24af48820Adb4bbb35174,
  Quote4a73b73165b241fd9bc33af0c830a3e8,
  Groupflow427938aa5aa54f0c98dc55f375baa2fa,
  Label3d7540825a264cdf86efB5b87102ed6e,
  TitleCa2233b7E8c74f59864c29eee8ea55a3,
  SignupPages67149a2bD0ac4e328d77E541ef4a785a,
  HeroAd0fc7c9Af5a4bd8A570C12a9685cd9e,
  Button463c08a4D76343b7B2366484975f081e,
  Title1714bc602cea4888913eFc205c90d2b8,
  PostSlug8b990c15945940ea98c5D663f7d10e4b,
  NavMenuSlugE570ed059508472fB32645c0e5d2ab0f,
  AboutSusan00fefa8d45104a77A9b7C00887756745,
  MarketingCardsB19d670c610340b4B6da8956769973ad,
  HeaderF2bb9e0e231b4a19897c587ab1668edf,
  BackgroundImage87d6cc1357cf455f85174d4613f83399,
  NavMenuSlug67f13a2a4a364476Aba209dadbfc5ce3,
  HrefD74c1b5e652f43dbBa51Fdebf4eb889d,
  AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2,
  Navigation1664b0d92f6d416dB543D1709ae4ad21,
  FooterFa6b7ad322d64e29Bf393f336f74879a,
  ImageUrl44298cbb6a9043c89f744bb22c869c4d,
  Label63155f951f664795Acb98c9149697494,
  TitleDdb8c69c2fd14962B5163d0b0997500a,
  PageQuote0bbc1af48c91494eAb711248b9ad9d8e,
  WelcomeBackF0edfa85721746e6Baa163aaf5baf944,
  AboutSusanHeader18e42361448a4e8c81c165df489bc0d9,
  Footer01dff936Ab1246639ee2Be72486f2456,
  Label7ab22ddf3fe04920Bb21948824440060,
  Class3d3ddf317ce147b98de333dfe5bda8e9,
  ResourcesPageA5d18669Cb754de894b599a91c2295cb,
  Body135f3a919b0747ed8535Bd8de92cd7ee,
  Hero71312f941c3a4333B140855716ad0591,
  HeaderTop6e971ef4731d465fA56c933e1e7490db,
  NavMenuSlugC65f10944c2247e1835d2b572a2b0b6f,
  ShortHeader7bb2acae24044f768a5a57e571b8807b,
  LinkedLogoC8365107Cfa1426aAb34D4a818ad3102,
  LinkedLogoDa69d91361ec451cA764Fd5bd42b7d9f,
  Body0053ad2561d7489cB533A335f7383977,
  Navigation083066daB7d043c991061508edd4e79a,
  DefaultHeader3d113915A8934369Ac694ff505c6ace8,
  HeaderTop712c5c4eEb2841fa87119e3d2b6118e3,
  Copyright14e4f454Cbb34893887f337d05f27bd9,
  Navigation0fc89265Ac484d41A4722db5e50e956c,
  ContentSlug29238b78Bf614910AfffA5e92b367b35,
  DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd,
  FooterAa230a0922554d3a9392D034e2685347,
  HeaderE223a33a14064b0aAbd09d8fb1e71f8f,
  FooterF7d68e3fF8484189827944021593c805,
  Title44385f7dD4a6469d850800b3bb1f8e2f,
  SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6,
  NavMenuSlug99ee6e5812aa48b9Ae4995ea2648f0b0,
  FooterA743e2660f1246ccA86bCcf760970e1d,
  HeaderF8e603aa57d74615A05611acd6bcf52b,
  NavMenuSlug5bb8102999f5414eA33313e34fd4d09d,
  Header91295fb426174969973843bb49aa96f6,
  ButtonClassB6d37220Fbea411fA9a191c3d4781f76,
  Header4352ea0b34a9435891499cd55411cd1d,
  Title8d98d4e13d9e490e9824Ecadcb020f92,
  AboutSusanMain02331a21860442819c99F7638b869459,
  TitleF4b47308510247a59ee46cee6bed721b
}

export default Components;