const ROUTES = {
  "/": {
    "name": "HomePage40cad30dD7f9420dAb0b10fff5ac9ade",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesB84033ba86334a72A27f60aea56b74a5",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage8272e800F2a64481Bdf8Aff6220b4314",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage31b1453585cb412eA10018d0c90dfc01",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/about/offerings": {
    "name": "AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2",
    "type": "HTML",
    "key": "about-offerings"
  },
  "/about/susan": {
    "name": "AboutSusan00fefa8d45104a77A9b7C00887756745",
    "type": "HTML",
    "key": "about-susan"
  },
  "/events/public": {
    "name": "PublicEventsPage9ca0ec3eB8a84869A86a50b76675060c",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageA91e5a5cEfc141988891A26885fe31e5",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages51f059fbB4304cb6A14bB58ab90a1914",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePagesBbc514a6F5e0487897f6Ae00b27e0983",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPageA5d18669Cb754de894b599a91c2295cb",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages67149a2bD0ac4e328d77E541ef4a785a",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;